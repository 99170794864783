window.Vue = require("vue");
require("../html/box-list/es-form")(Vue);
require("../html/box-list/es-form-item")(Vue);
require("../html/box-list/es-input")(Vue);
require("../html/box-list/es-modal")(Vue);
require("../html/box-list/es-select")(Vue);
require("../html/box-list/es-option")(Vue);
require("../html/box-list/es-radio")(Vue);
require("../html/box-list/es-filter-list")(Vue);
require("../html/box-list/es-autocomplete")(Vue);

require("../html/box-list/charge-remark")(Vue);
require("../html/box-list/charge-entry")(Vue);

//npm 公用包
import VueLazyComponent from "@xunlei/vue-lazy-component";
Vue.use(VueLazyComponent);
import VueElementLoading from "vue-element-loading";
Vue.component("VueElementLoading", VueElementLoading);
import dayjs from "dayjs"
import {
  Pagination,
  Row,
  Col,
  Autocomplete,
  Select,
  Option,
  Button,
  Popover,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Upload,
  Switch,
  Loading,
  Radio,
  RadioButton,
  RadioGroup,
  DatePicker,
  Cascader,
  Progress,
  Tabs,
  TabPane,
  Input,
  Icon,
  Dialog,
  Table,
  TableColumn,
  Form,
  FormItem,
  Message,
  InputNumber,
  MessageBox,
  Tag,
  Timeline,
  TimelineItem,
  Card,
  Image,
  Carousel,
  CarouselItem,
  Divider,
  TimePicker,
  Rate,
  Tooltip,
  Badge,
  Tree,
  Transfer,
  Popconfirm,
  Descriptions,
  DescriptionsItem,
  Result,
  Alert,
  Steps,
  Step,
  Collapse,
  Drawer,
  CollapseItem
} from "element-ui";
Vue.use(Tree);
Vue.use(Pagination);
Vue.use(Row);
Vue.use(Col);
Vue.use(Autocomplete);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(Switch);
Vue.use(Cascader);
Vue.use(Loading);
Vue.use(Radio);
Vue.use(RadioButton);
Vue.use(RadioGroup);
Vue.use(DatePicker);
Vue.use(Progress);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Form);
Vue.use(InputNumber);
Vue.use(FormItem);
Vue.use(Tag)
Vue.use(Pagination);
Vue.use(Cascader);
Vue.use(Image);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Divider);
Vue.use(TimePicker);
Vue.use(Rate);
Vue.use(Tooltip)
Vue.use(Badge)
Vue.use(Tree)
Vue.use(Transfer)
Vue.use(Popconfirm)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Result)
Vue.use(Alert)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Drawer)
import PinyinMatch from "pinyin-match";

Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;

import AcceptGroupSelect from "dk-accept-group-select"
Vue.component("dk-accept-group-select", AcceptGroupSelect)

import GlobalDriverInfo from "dk-global-driver-info-modal"
// Vue.component("dk-global-driver-info-modal", GlobalDriverInfo)


const ModalConstructor = Vue.extend(GlobalDriverInfo);
const instance = new ModalConstructor();


const EventBus = new Vue();
window.dayjs = dayjs
Object.defineProperties(Vue.prototype, {
  $eventBus: {
    get: function () {
      return EventBus
    }
  }
})


instance.$mount();
document.body.appendChild(instance.$el);